import type { Row } from "@electric-sql/client";
import { useShape } from "./useShape";
import type { TrashItem } from "#build/interfaces/trashItem";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/vue-query";

function getUserPictures(userId: string, data: Row[]): TrashItem[] {
  if (!data) return []

  const sortedPictures = data
    .sort((a: Row, b: Row) => {
      return new Date(b.ts as string).getTime() - new Date(a.ts as string).getTime()
    }) as unknown as TrashItem[]

  return sortedPictures.filter((item: TrashItem) => item.pbjson?.userId === userId)
}

export default function useGlobalPics(userId: string) {
  const queryClient = useQueryClient()
  const config = useRuntimeConfig()
  const pageSize = 15

  const urlPath = config.public.ELECTRIC_SQL_URL + 'v1/shape'
  const options = {
    url: urlPath,
    table: 'trashitempb',
  }

  const shapeData = useShape(options);

  const {
    data: picsTakenData,
    isLoading: isLoadingPicsTaken
  } = useQuery({
    queryKey: ['userPicsTaken'],
    queryFn: () => {
      return Promise.resolve(
        getUserPictures(userId, shapeData.value?.data).length
      )
    },
    enabled: !!shapeData.value,
  })

  const picsTaken = computed(() => picsTakenData.value || 0)

  const {
    data: picturesData,
    isLoading: isLoadingPictures,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['userPictures'],
    queryFn: ({ pageParam }) => {
      const start = pageParam * pageSize
      const end = start + pageSize

      return Promise.resolve(
        getUserPictures(userId, shapeData.value?.data).slice(start, end)
      )
    },
    getNextPageParam: (_, allPages) => {
      const totalItems = picsTaken.value
      const loadedItems = allPages.flat().length

      if (loadedItems >= totalItems) {
        return undefined
      }
      return allPages.length
    },
    initialPageParam: 0,
  })

  watch(
    () => shapeData.value?.data,
    () => {
      queryClient.invalidateQueries({ queryKey: ['userPicsTaken'] })
      queryClient.invalidateQueries({ queryKey: ['userPictures'] })
    }
  )

  const pictures = computed<TrashItem[]>(() => picturesData.value?.pages.flat() || [])
  const isPending = computed(() => isLoadingPicsTaken.value || isLoadingPictures.value)

  return {
    picsTaken,
    pictures,
    isPending,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  }
}
